@import "primereact/resources/themes/lara-light-indigo/theme.css";  
@import "primereact/resources/primereact.min.css";                  
@import "primeicons/primeicons.css";    
@import 'semantic-ui-css/semantic.min.css';

@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,500,300,700);

* {
  font-family: Open Sans;
}
body, html {
	height: 100%;
	width: 100%;
	margin: 0;
  }
  .full-screen-div {
	  width: 100vw;
	  height: 100%;
	  min-height: 100%;
	  box-sizing: border-box;
	  overflow: hidden;
  }

.iconLogin {
	margin-top: 10px;
	margin-top: 2% !important;
	margin-left: 4%;
}

.logoTop {
    width: 100px !important;
    margin-right: 50px !important;
}

.colorRed {
	color: red;
	margin-right: 10px;
}

.ui.menu:first-child {
	margin: 0;
	border-bottom: 1px solid;
}

.p-card-body {
    overflow:hidden;
    min-height: 100px;
	max-height: 100px;
}

.contentMid {
    background-color: white;
    height: 90vh;
	width: 100%;
   	background-size: cover;
   	background-repeat: no-repeat;
   	background-position: center center;
	position: relative;
	text-align: center;
	overflow: auto;
	overflow-x: hidden;
}
.imgStyle {
    height: 90vh;
	width: 100%;
   	background-size: cover;
   	background-repeat: no-repeat;
   	background-position: center center;
	opacity: 0.375;
}

.p-menubar {
	background-color:#ffffff !important;
	border-radius: 0;
}

.p-menuitem-icon {
	color: black
}


.footer-distributed{
	background: darkgray;
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
	box-sizing: border-box;
	width: 100%;
	text-align: left;
	font: bold 12px sans-serif;
	padding: 0px 50px;
    position: absolute;
    bottom: 0;
	height: 7vh;
}


.footer-distributed .footer-left,
.footer-distributed .footer-center,
.footer-distributed .footer-right{
	display: inline-block;
	vertical-align: top;
}

.footer-distributed .footer-left i{
	font-size: 15px;
	border-radius: 50%;
	vertical-align: middle;
	margin-top: 10px;
}


.footer-distributed .footer-left p{
	display: inline-block;
  	font-weight:500;
	vertical-align: middle;
	margin:0;
	margin-top: 7px;
}

.footer-distributed .footer-left{
	width: 34%;
}

.footer-distributed .footer-company-name{
	color:  #222;
	font-size: 12px;
	font-weight: normal;
	margin-top: 30px;
}

.sometest {
   width: 100%;
   height: auto;
   background-size: cover;
   background-repeat: no-repeat;
   background-position: center;
}

/* Footer Center */

.footer-distributed .footer-center{
	width: 46%;
	margin-top: 15px;
}

.footer-distributed .footer-center i{
	font-size: 15px;
	border-radius: 50%;
	vertical-align: middle;
	margin-top: 10px;
}


.footer-distributed .footer-center p{
	display: inline-block;
  	font-weight:400;
	vertical-align: middle;
	margin:0;
	margin-top: 7px;
}


.footer-distributed .footer-center p a{
	color:  lightseagreen;
	text-decoration: none;;
}


/* Footer Right */

.footer-distributed .footer-right i.fa-envelope{
	font-size: 15px;
	line-height: 25px;
	margin-left: 2px;
	color: black
}

.footer-distributed .footer-right p{
	display: inline-block;
	font-weight:400;
	margin:0;
}


.footer-distributed .footer-right a p{
	color:  darkslategray;
	text-decoration: none;
	margin-left: 15px;
}

.footer-distributed .footer-right{
	width: 20%;
	margin-top: 30px;
}

.footer-distributed .footer-icons{
	margin-top: 25px;
}

.footer-distributed .footer-icons a{
	display: inline-block;
	width: 35px;
	/* height: 35px; */
	cursor: pointer;
	border-radius: 2px;

	font-size: 12px;
	color: #ffffff;
	 text-align: center;
	line-height: 35px; 

	margin-right: 3px;
	margin-bottom: 5px;
}
.p-menuitem-icon {
	color: red !important;
}
.aboutText {
	position: absolute;
	top: 40%;
	left: 50%;
	transform: translate(-50%, -50%);	

}
ul.square {
	list-style-type: square;
		
}
.listStyle {
	position: absolute;
	top: 55%;
	left: 28%;
	transform: translate(-50%, -50%);
}
.padAndMar {
	padding-top: 10%;
	font-weight: bold;
	font-size: 30px;
	text-align: start;
}
.frontHeader {
	position: absolute;
	top: 15%;
	left: 20%;
	transform: translate(-50%, -50%);	
	font-size: 50px;
	margin-left: 15%;
}
.footerMargin {
	margin-top: 3%;
}
.footerStyleCenter {
	margin-left: 15%;
}

.aboutHeader {
	font-size: 50px;
	margin-top: 25%;
	font-weight: bold;
}
.aboutPstyle {
	font-weight: bolder;
	font-size: 25px;
	margin-top: 10%;
}

.machinesMargin {
	margin-top: 10px;
	margin-top: 2% !important;
	margin-left: 4%;
}

.marginBotTop20 {
	margin-bottom: 2%;
	margin-left: 7% !important;
	margin-top: 2% !important;
}

.marginTop {
	margin-top: 20px;
}

.imageStyling {
	height: 100%;
	width: 100%;
	max-width: 100%;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	border-bottom: #222 solid;
}

.p-card-header img {
	height: 250px;
}
.p-card {
	max-width: 350px;
	min-width: 330px;
}

.ui.menu {
	margin:0;
}


.topMenuIcon {
	margin-left: 1%;
	color: red;
	margin-right: 10px;
	margin-top: 5px;
}
.topMenuP {
	color: black;
	font-size: 16px;
	font-weight: bold;
}

.topMenuSpan {
	margin-left: 10px;
}

.p-menubar .p-menuitem {
	margin-left: 20px;
	
}

a:link { text-decoration: none; }


a:visited { text-decoration: none; }


a:hover { text-decoration: none; }


a:active { text-decoration: none; }

.iconSpace {
	margin-left: 10px;
	margin-bottom: 5px;
	cursor: pointer;
}

.p-submenu-list {
	z-index: 10000;
}

.loginIcon {
	margin-top: 15px;
	margin-right: 20px;
	cursor: pointer;
}

.textContent {
}

.notPhone {
}
.phone {
	display: none;
}

/* If you don't want the footer to be responsive, remove these media queries */


/*15" screen - Need on similar for a 24" screen*/
@media (max-width: 1900px) and (min-width: 881px) {

	.contentMid{
		height: 85vh;
	}

	.footer-distributed{
		height: 10vh;
	}

	.footer-distributed .footer-center{
		width: 46%;
		margin-top: 10px;
	}
	
	.footerMargin {
		margin-top: 5%;
	}


	.listStyle {
		top: 53%;
		left: 25%;
		transform: translate(-50%, -50%);
		margin-left: 2%;	
	}
	.padAndMar {
		font-weight: bold;
		font-size: 18px;
		padding-top: 10%;
	}
	.frontHeader {
		top: 15%;
		left: 20%;
		transform: translate(-50%, -50%);
		font-size: 30px;
		font-weight: bold;	
	}
	.aboutPstyle {
		font-weight: bolder;
		font-size: 20px;
		margin-top: 2%;
	}
	.aboutHeader {
		font-size: 30px;
		margin-top: 25%;
	}
	
	.marginBotTop20 {
		margin-left: 4% !important;
		margin-bottom: 4% !important;
	}

	.machinesMargin {
		margin-top: 10px;
		display: flexbox;
		margin-left: 4%;
	}
	.p-card-body {
		overflow:auto;		
	}
	.p-card {
		min-width: 0;
	}
	.textContent {
		height: 85vh !important;
		overflow: hidden;
	}
}


/*Tablet - phones can be corrected to fit better*/
@media (max-width: 880px) and (min-width: 380px) {

	.notPhone {
		display: none;
	}
	.phone {
		display: inline;
	}
	
	.iconLogin {
		margin-top: 10px;
		margin-top: 2% !important;
		margin-left: 10%;
	}

	.footer-distributed{
		font: bold 14px sans-serif;
		height: 20vh;
	}

	.footer-distributed .footer-left,
	.footer-distributed .footer-center,
	.footer-distributed .footer-right{
		display: block;
		width: 100%;
		margin-bottom: 0px;
		text-align: center;
	}

	.footer-distributed .footer-center i{
		margin-left: 0;
		margin-bottom: 5px;
	}
	.listStyle{
		top: 44%;
		left: 50%;
		transform: translate(-50%, -50%);
		margin-top: 5%;	
	}
	.padAndMar {
		font-weight: bold;
		font-size: 12px;
	}
	.frontHeader {
		top: 12%;
		left: 37%;
		transform: translate(-50%, -50%);	
		font-size: 16px;
		font-weight: bold;
	}
	.aboutText {
		position: absolute;
		top: 40%;
		left: 50%;
		transform: translate(-50%, -50%);	
	
	}
	.aboutHeader {
		font-size: 24px;
		margin-top: 25%;
	}
	.aboutPstyle {
		font-weight: bolder;
		font-size: 10px;
		margin-top: 10%;
	}
	.p-card {
		max-width: 350px;
		min-width: 320px;
	}
	.machinesMargin {
		margin-top: 2% !important;
		margin-left: 4%;
		min-width: 330px;
		margin-bottom: 10px;
	}

	.marginBotTop20 {
		margin-left: 2% !important;
	}

	.contentMid{
		height: 70vh;
	}
	.textContent {
		height: 73vh !important;
		overflow: hidden;
	}
	.imgStyle {
    height: 100%;
	width: 100%;
   
}

}
/*Smaller - phones can be corrected to fit better*/
  @media (max-width: 379px) {

	.notPhone {
		display: none;
	}
	.phone {
		display: inline;
	}

	.iconLogin {
		margin-top: 10px;
		margin-top: 2% !important;
		margin-left: 10%;
	}
	.imgStyle {
    height: 100%;
	width: 100%;
	}		

	.footer-distributed{
		font: bold 14px sans-serif;
		height: 25vh;
	}

	.footer-distributed .footer-left,
	.footer-distributed .footer-center,
	.footer-distributed .footer-right{
		display: block;
		width: 100%;
		margin-bottom: 0px;
		text-align: center;
	}

	.footer-distributed .footer-center i{
		margin-left: 0;
		margin-bottom: 5px;
	}
	.listStyle{
		top: 44%;
		left: 50%;
		transform: translate(-50%, -50%);	
		margin-top: 5%;	
	}
	.padAndMar {
		font-size: 12px;
		font-weight: bold;
	}
	.frontHeader {
		top: 12%;
		left: 37%;
		transform: translate(-50%, -50%);	
		font-size: 15px;
	}
	.aboutText {
		position: absolute;
		top: 35%;
		left: 50%;
		transform: translate(-50%, -50%);	
		width: 80%;
	
	}
	.aboutHeader {
		font-size: 20px;
		margin-top: 25%;
	}
	.aboutPstyle {
		font-weight: bolder;
		font-size: 9px;
		margin-top: 10%;
	}
	.p-card {
		max-width: 300px;
		min-width: 300px;
	}
	.machinesMargin {
		margin-top: 2% !important;
		margin-left: 4%;
		min-width: 330px;
		margin-bottom: 10px;
	}

	.marginBotTop20 {
		margin-left: 2% !important;
		margin-bottom: 10%;
	}

	.contentMid{
		height: 70vh;
	}
	.textContent {
		height: 73vh !important;
		overflow: hidden;
	}

	.footerMargin {
		margin-top: 0%;
	}

}  


@media (max-height: 650px) and (min-height: 580) { 

	.iconLogin {
		margin-top: 10px;
		margin-top: 2% !important;
		margin-left: 10%;
	}

	.marginBotTop20 {
		margin-bottom: 30%;
	}

	.footer-distributed{
		height: 20vh;
	}

	.aboutText {
		top: 40%;
	
	}
}

@media (max-height: 580px) { 

	.iconLogin {
		margin-top: 10px;
		margin-top: 2% !important;
		margin-left: 10%;
	}

	.marginBotTop20 {
		margin-bottom: 35%;
	}

	.footer-distributed{
		height: 20vh;
	}

	.aboutText {
		top: 40%;
	
	}

	.padAndMar {
		font-size: 7px;
	}

	.frontHeader{
		font-size: 12px;
		top: 15%;
	}

	.aboutPstyle {
		margin-top: 4%;
	}
}	


